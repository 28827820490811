import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img className="px-10 py-10 h-24" src="/NytroLogo.png"></img>
      <h1 className="text-3xl py-20">
        Site coming soon!
      </h1>
    </div>
  );
}

export default App;
